/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Billing from "../../../Billing";
import ThemeSwitch from "../../../Switch/Theme";
import { DataContext } from "context/DataContext";
import { txtCartFetch } from "utilifyFunctions";

const SidebarNav = ({ pages, isMd, handleOpen, onClose }) => {
	const location = useLocation();
	const theme = useTheme();
	const navigate = useNavigate();
	const { setShowBillingModal } = useContext(DataContext);
	const { mode } = theme.palette;
	const [activeNav, setActiveNav] = useState("");
	const [activeLink, setActiveLink] = useState(null);
	const [firstRoute, setFirstRoute] = useState("");
	const [secondRoute, setSecondRoute] = useState("");
	const [activeChildIndex, setActiveChildIndex] = useState(null);
	const [activeItemIndex, setActiveItemIndex] = useState(null);
	const [externalLink, setExternalLink] = useState("");

	useEffect(() => {
		let link = location && location.pathname ? location.pathname : "";
		setActiveLink(link);
		let breakDown = link.split("/");
		setFirstRoute(`/${breakDown[1]}`);
		setSecondRoute(breakDown[2] ? `/${breakDown[2]}` : "");
		for (let x of pages) {
			if (`/${breakDown[1]}` == x.href) {
				let children = x?.children;
				for (let i in children) {
					if (link == children[i].href || link == `${children[i].href}/empty`) {
						// console.log(
						// 	link,
						// 	children[i].href,
						// 	"here1",
						// 	`${children[i].href}/empty`,
						// 	breakDown[1]
						// );
						setActiveChildIndex(parseInt(i));
						// setActiveItemIndex(i);
						// setActiveLink(link);
						setActiveNav(`/${breakDown[1]}`);
						setOpenItems((prevState) => ({
							[`/${breakDown[1]}`]: true,
						}));
					}
				}
			}
		}
		checkExternalLink(link);
		// onClose()
	}, [location]);
	const checkExternalLink = (link) => {
		let breakDown = link.split("/");
		let childLink = breakDown[2];
		if (!childLink) {
			setExternalLink("");
			return;
		}
		for (let x of pages) {
			if (`/${breakDown[1]}` == x.href) {
				let children = x?.children;
				if (children) {
					for (let item of children) {
						if (item?.href == link || `${item?.href}/empty` == link) {
							setExternalLink("");
							return;
						}
					}
				}

				setFirstRoute("");
				setExternalLink(`/${breakDown[1]}`);
				setOpenItems({});
				break;
			}
		}
	};
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

	const [openItems, setOpenItems] = useState({}); // State to track open/closed items

	const toggleItem = (item, index) => {
		setActiveNav(item.href);
		setActiveChildIndex(-1);
		setOpenItems((prevState) => ({
			[item.href]: true,
		}));
		setActiveLink(item.href);
		setActiveItemIndex(index); // Store the index of the clicked item
		if (!item?.children) {
			onClose();
		}
	};

	const toggleChildItem = (index, parentItem, link) => {
		setActiveChildIndex(index);
		setActiveNav(parentItem.href);
		setActiveLink(link);
		onClose();
	};
	const lessThen100 = (value) => {
		if (value > 100) {
			return 100;
		}
		return value;
	};
	const handleLogout = async () => {
		console.log("handleLogout");
		localStorage.clear();
		navigate("/");
		let response = await txtCartFetch("POST",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/login/logout");
		navigate("/login");
	};
	const { themeToggler } = theme;

	let height = 0;

	if (activeChildIndex === 0) {
		height = "20.5px";
	} else if (activeChildIndex === 1) {
		height = "56.5px";
	} else if (activeChildIndex > 1) {
		// Calculate the height based on the AP pattern
		height = 93 + (activeChildIndex - 2) * 36.8 + "px";
	}

	return (
		<Box
			id="main-sideba3"
			display={"flex"}
			overflow={"auto"}
			sx={{
				"::-webkit-scrollbar": {
					width: "0 !important",
				},
			}}
			flexDirection={"column"}
			justifyContent={"space-between"}
			height={"100vh"}
		>
			<Box id="newcheck">
				{isMd ? (
					<Box
						id="logo"
						sx={{
							padding: "0px 20px",
						}}
						// mt={1}
						// mb={1}
					>
						<img
							src="/logos/logo3.svg"
							width={"80%"}
							// height={"100%"}
							alt="Logo"
						/>
					</Box>
				) : (
					<Box
						id="logo"
						width={"70%"}
						sx={{
							padding: "0px 20px",
						}}
					>
						<img
							src="/logos/logo.svg"
							width={"133px"}
							height={"39px"}
							alt="Logo"
						/>
					</Box>
				)}

				<Box padding={"5px 10px"}>
					{pages.map((p, i) => (
						<Box
							key={i}
							mb={"5px"}
							className={`${firstRoute === p.href ? "myActive" : "nomyActive"}`}
						>
							<style>
								{`
										.myActive::before {
											height: ${height};
											border-bottom: ${height ? "1.5px solid #394c64" : "none"}
											
										}
								`}
							</style>
							<>
								<Link to={p.href} target={p.target}>
									<Button
										fullWidth
										sx={{
											borderLeft:
												activeLink == p.href ||
												(externalLink == p?.href && "3px solid #0A84FE"),
											justifyContent: "flex-start",
											color:
												activeLink === p.href || externalLink == p?.href
													? "#ffff"
													: mode == "light"
													? "#818CA5"
													: "#686979",
											backgroundColor:
												activeLink === p.href || externalLink == p?.href
													? "#062448"
													: "transparent",
										}}
										onClick={() => toggleItem(p)}
										className={activeLink === p.href ? "activeParent" : ""}
									>
										<span
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											{activeLink === p.href && (
												<span className="activeBorder"></span>
											)}
											<img
												src={`/icons/${
													activeLink === p.href ||
													firstRoute == p.href ||
													externalLink == p.href
														? p.iconClick
														: mode == "light"
														? p.icon
														: p.darkIcon
												}`}
												alt={p.title}
												className="icons fillblue"
												data-image-type="svg"
											/>
											<Typography
												variant="p"
												fontSize={"14px"}
												fontWeight={"400"}
											>
												{p.title}
											</Typography>
											{/* {p.title === "Inbox" && <span className="badge">4</span>} */}
										</span>
									</Button>
								</Link>
								{openItems[p.href] && p.children && (
									<Box padding={"0px"}>
										{p.children.map((child, j) => (
											<Box key={j} position={"relative"} padding={"0px"}>
												{activeLink == child.href && activeChildIndex === j && (
													<Box className="arrow-head"></Box>
												)}
												{activeLink === `${child.href}/empty` &&
													activeChildIndex === j && (
														<Box className="arrow-head"></Box>
													)}
												<Link
													to={child.href}
													target={child.target}
													className={`${
														activeLink === child.href && activeChildIndex === j
															? "arrow-active"
															: ""
													}`}
													onClick={() => {
														toggleChildItem(j, p, child.href);
													}}
													style={{
														textDecoration: "none",
														color: "#ffffff",
														marginTop: "15px",
													}}
												>
													<Button
														fullWidth
														width={"100%"}
														className={`${
															activeLink === child.href &&
															activeChildIndex === j
																? "button-active"
																: ""
														}`}
														sx={{
															borderLeft:
																activeLink === child.href ||
																activeLink === `${child.href}/empty`
																	? "3px solid #0A84FE"
																	: "none",
															justifyContent: "flex-start",
															color:
																activeLink === child.href ||
																activeLink === `${child.href}/empty`
																	? "#ffff"
																	: mode == "light"
																	? "#818CA5"
																	: "#686979",
															backgroundColor:
																activeLink === child.href ||
																activeLink === `${child.href}/empty`
																	? "#062448"
																	: "transparent",
															":hover": {
																backgroundColor:
																	activeLink === child.href ||
																	activeLink === `${child.href}/empty`
																		? "#0A84FE1A"
																		: "transparent",
															},
														}}
													>
														{/* {activeLink === child.href && (
															<div className="navBar">&nbsp;</div>
														)} */}
														<span>
															{child.icon && (
																<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </>
															)}
															{child.title}
														</span>
													</Button>
												</Link>
											</Box>
										))}
									</Box>
								)}
							</>
						</Box>
					))}
				</Box>
			</Box>

			<Box padding={2} mb={isSmall ? "5rem" : "10px"}>
				<Box width={"100%"}>
					{/* <img src="/features/feature-2.svg" width={"100%"} alt="Feature" /> */}

					<img
						onClick={() => {
							setShowBillingModal(true);
						}}
						id="pointerCursor"
						src={
							mode === "light"
								? "/features/Left-cta2.svg"
								: "/topbar/Left-cta.svg"
						}
						alt="People Icon"
						width={"100%"}
					/>
				</Box>
				{/* <Billing open={open} handleClose={handleClose}/> */}
				<Box mb={"5px"} mt={2}>
					<Link to={"/settings"}>
						<Button
							fullWidth
							sx={{
								borderLeft:
									activeLink === "/settings" ? "3px solid #0A84FE" : "none",
								justifyContent: "flex-start",
								color:
									activeLink === "/settings"
										? "#ffff"
										: mode == "light"
										? "#818CA5"
										: "#686979",
								backgroundColor:
									activeLink === "/settings" ? "#062448" : "transparent",
							}}
							className={activeLink === "/settings" ? "activeParent" : ""}
						>
							<span
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<img
									src={
										activeLink === "/settings"
											? `/icons/settings-click.svg`
											: mode == "light"
											? `/icons/settings.svg`
											: `/icons/dark/settings.svg`
									}
									className="icons fillblue"
									data-image-type="svg"
								/>
								<Typography variant="p" fontSize={"14px"} fontWeight={"400"}>
									{"Settings"}
								</Typography>
							</span>
						</Button>
					</Link>
				</Box>
				<Box mb={2}>
					<Button
						disableRipple
						fullWidth
						sx={{
							justifyContent: "flex-start",
							color: mode == "light" ? "#818CA5" : "#686979",
							":hover": {
								backgroundColor: "transparent",
							},
							":active": {
								backgroundColor: "transparent",
							},
						}}
					>
						<span
							style={{
								display: "flex",
								alignItems: "center",
							}}
						>
							<img
								src={
									mode == "light"
										? "/icons/logout.svg"
										: "/icons/dark/logout.svg"
								}
								className="icons fillblue"
								data-image-type="svg"
							/>
							<Typography
								variant="p"
								fontSize={"14px"}
								fontWeight={"400"}
								onClick={handleLogout}
							>
								{"Logout"}
							</Typography>
							<Box position={"absolute"} right={0} top={"9px"}>
								<ThemeSwitch mode={mode} themeToggler={themeToggler} />
							</Box>
						</span>
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

SidebarNav.propTypes = {
	pages: PropTypes.array.isRequired,
	onClose: PropTypes.func,
};

export default SidebarNav;

// border-bottom: ${activeChildIndex !== -1 ? "1px solid white" : "none"};
