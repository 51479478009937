import SecondaryButton from "components/Buttons/SecondaryButton";
import EmptyComponentTable from "components/Table/EmptyComponentTable";
import React from "react";

export default function EmptyTableData() {
	return (
		<EmptyComponentTable
			title="You have no subscribers yet"
			desc="Contact Support to upload your subscriber CSV from Shopify or another SMS app"
		/>
	);
}
