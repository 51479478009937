import { Box, Grid, Typography, useMediaQuery, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import FunnelChart from "../../../components/Chart/FunnnelChart";
import { useTheme } from "@emotion/react";
import { txtCartFetch } from "utilifyFunctions";
import { parseDateFilter } from "utilifyFunctions";

export default function FunnelSection({ mode, filter }) {
	const theme = useTheme();
	const lightMode = mode == "light" ? true : false;
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));

	const [conversations,setConversations] = useState(null);
	const [replies,setReplies] = useState(null);
	const [recovered,setRecovered] = useState(null);
	const [replyPercentage, setReplyPercentage] = useState(null);
	const [soldPercentage, setSoldPercentage] = useState(null);
	const [conversationsValue,setConversationsValue] = useState(null);
	const [repliesValue,setRepliesValue] = useState(null);
	const [recoveredValue,setRecoveredValue] = useState(null);

	const loadMetrics = function(filter){
		setConversations(null);
		setReplies(null);
		setRecovered(null);
		setConversationsValue(null);
		setRepliesValue(null);
		setRecoveredValue(null);
		setReplyPercentage(null);
		setSoldPercentage(null);

		let dateRange = parseDateFilter(filter)
		let urlDateRange = (dateRange ? "?start="+dateRange.start+"&end="+dateRange.end : "");

		setTimeout(() => {
			getFunnel(urlDateRange);
		}, 750); 
	}

	const getFunnel = async function(dateRange){
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/carts/funnel"+dateRange);
		console.log(response);
		if(response){
			setConversations(response.totalText);
			setReplies(response.repliesText);
			setRecovered(response.soldText);
			setConversationsValue(response.total);
			setRepliesValue(response.replies);
			setRecoveredValue(response.sold);
			setReplyPercentage(response.replyPercentage);
			setSoldPercentage(response.soldPercentage);
		}
	}

	useEffect(() => {
		loadMetrics(filter)
	}, [filter]);
	return (
		<Grid container>
			<Grid item xs={4}>
				<Box
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"space-between"}
					sx={{
						borderRight: lightMode ? "1px solid #EBEBEB" : "1px solid #1E202D",
					}}
				>
					<Box  p={"0 20px"}>
								<Typography
									fontSize={belowSmall ? 10 : 16}
									fontWeight={600}
									color={lightMode ? "#7C7C85" : "#7C7C85"}
									lineHeight={"normal"}
									mb={"8px"}
								>
									Conversations
								</Typography>
								{conversations !== null && conversations !== undefined ? (
									<Box>
										<Typography
											fontSize={belowSmall ? 20 : 30}
											fontWeight={600}
											color={lightMode ? "#000D31" : "#FFF"}
											lineHeight={"normal"}
										>
										{conversations}
									</Typography>
									<Typography
										fontSize={belowSmall ? 10 : 16}
										fontWeight={600}
										color={lightMode ? "#7C7C85" : "#7C7C85"}
										lineHeight={"normal"}
									>
										&nbsp;
									</Typography>
									</Box>
								) : (
									<Box sx={{ width: '100%' }}>
										<Skeleton variant="rectangular" animation="wave" height={40} />
										<Skeleton animation="wave" />
									</Box>
								)}
					</Box>
					<Box height={150}>
						{ conversationsValue !== null && conversationsValue !== undefined ? (
							<FunnelChart
								data={[
									{
										name: "x",
										uv: conversationsValue,
									},
									{
										name: "y",
										uv: repliesValue,
									},
								]}
								opacity={0.2}
								lightMode={lightMode}
								domain={[0,conversationsValue > 0 ? conversationsValue : 1]}
							/>
						) : (
							<Box sx={{ width: '100%' }} p={"0 20px"}>
								<Skeleton variant="rectangular" animation="wave" height={150} />
							</Box>
						)}
					</Box>
				</Box>
			</Grid>
			<Grid item xs={4}>
				<Box
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"space-between"}
					sx={{
						borderRight: lightMode ? "1px solid #EBEBEB" : "1px solid #1E202D",
					}}
				>
					<Box  p={"0 20px"}>
								<Typography
									fontSize={belowSmall ? 10 : 16}
									fontWeight={600}
									color={lightMode ? "#7C7C85" : "#7C7C85"}
									lineHeight={"normal"}
									mb={"8px"}
								>
									Replied
								</Typography>
								{ replyPercentage !== null && replyPercentage !== undefined ? (
									<Box>
										<Typography
											fontSize={belowSmall ? 20 : 30}
											fontWeight={600}
											color={lightMode ? "#000D31" : "#FFF"}
											lineHeight={"normal"}
										>
											{replies}
										</Typography>
										<Typography
											fontSize={belowSmall ? 10 : 16}
											fontWeight={600}
											color={lightMode ? "#7C7C85" : "#7C7C85"}
											lineHeight={"normal"}
										>
											{replyPercentage}
										</Typography>
									</Box>
								) : (
									<Box sx={{ width: '100%' }}>
										<Skeleton variant="rectangular" animation="wave" height={40} />
										<Skeleton animation="wave" />
									</Box>
								)}
					</Box>
					<Box height={150}>
						{ repliesValue !== null && repliesValue !== undefined ? 
						(
							<FunnelChart
								data={[
									{
										name: "x",
										uv: repliesValue,
									},
									{
										name: "y",
										uv: recoveredValue,
									},
								]}
								opacity={0.15}
								lightMode={lightMode}
								domain={[0,conversationsValue > 0 ? conversationsValue : 1]}
							/>
						) : (
							<Box sx={{ width: '100%' }} p={"0 20px"}>
								<Skeleton variant="rectangular" animation="wave" height={150} />
							</Box>
						)}
					</Box>
				</Box>
			</Grid>
			<Grid item xs={4}>
				<Box
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"space-between"}
				>
					<Box p={"0 20px"}>
						<Typography
							fontSize={belowSmall ? 10 : 16}
							fontWeight={600}
							color={lightMode ? "#7C7C85" : "#7C7C85"}
							lineHeight={"normal"}
							mb={"8px"}
						>
							Recovered
						</Typography>
						{soldPercentage !== null && soldPercentage !== undefined ? (
							<Box>
								<Typography
									fontSize={belowSmall ? 20 : 30}
									fontWeight={600}
									color={lightMode ? "#000D31" : "#FFF"}
									lineHeight={"normal"}
								>
									{recovered}
								</Typography>
								<Typography
									fontSize={belowSmall ? 10 : 16}
									fontWeight={600}
									color={lightMode ? "#7C7C85" : "#7C7C85"}
									lineHeight={"normal"}
								>
									{soldPercentage}
								</Typography>
							</Box>
						) : (
							<Box sx={{ width: '100%' }}>
								<Skeleton variant="rectangular" animation="wave" height={40} />
								<Skeleton animation="wave" />
							</Box>
						)}
					</Box>
					<Box height={150}>
						{recoveredValue !== null && recoveredValue !== undefined ? (
								<FunnelChart
								data={[
									{
										name: "x",
										uv: recoveredValue,
									},
									{
										name: "y",
										uv: 0,
									},
								]}
								opacity={0.07}
								lightMode={lightMode}
								domain={[0,conversationsValue > 0 ? conversationsValue: 1]}
							/>
						) : (
							<Box sx={{ width: '100%' }} p={"0 20px"}>
								<Skeleton variant="rectangular" animation="wave" height={150} />
							</Box>
						)}
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
}


