import React from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import NextButton from "./Buttons/NextButton";

export default function SelectPlanStep  ({
	style,
	closeBillingModal,
	period,
	setPlanPeriod,
	periodSelectedStyle,
	periodNonSelectedStyle,
	selected,
	currentPlan,
	setPlanType,
    ChangeTab,
	skus,
	currentCycle
}) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Box sx={style} maxHeight={"80%"}>
			<Box mb={3} display={"flex"} justifyContent={"space-between"}>
				<Typography
					color={mode == "light" ? "#0A0A18" : "#FFF"}
					id="billing-modal-title"
					fontSize={"20px"}
					fontWeight={"600"}
				>
					Choose Your Plan
				</Typography>
				<img
					src="/icons/cut.svg"
					alt="close"
					onClick={closeBillingModal}
					id="pointerCursor"
				/>
			</Box>
			<Box
				mb={2}
				sx={{
					textAlign: belowTablet ? "center" : "",
					alignItems: belowTablet ? "center" : "",
					display: "flex",
					justifyContent: "space-between",
					padding: "12px 20px",
					backgroundColor: "#0081FF",
					flexDirection: belowTablet ? "column" : "row",
					borderRadius: "4px",
				}}
			>
				<Box mb={belowTablet ? 1 : 0}>
					<Typography fontSize={"12px"} color={"#FFF"}>
						Plan to stick around for a while?
					</Typography>
					<Typography fontSize={"14px"} fontWeight={"600"} color={"#FFF"}>
						Get 2 months free with annual billing.
					</Typography>
				</Box>
				<Box
					width={"210px"}
					sx={{
						display: "flex",
						borderRadius: "4px",
						gap: "5px",
						background: mode == "light" ? "#fff" : "#0C0D17",
						padding: "5px 5px",
					}}
				>
					<Box
						id="pointerCursor"
						sx={{
							background:
								period == "Monthly"
									? mode == "light"
										? "#CCE6FF"
										: "rgba(204, 230, 255, 0.10)"
									: "transparent",
							borderRadius: "4px",
							padding: "6px 20px",
						}}
						onClick={() => {
							setPlanPeriod("Monthly");
						}}
					>
						<Typography
							style={
								period == "Monthly"
									? periodSelectedStyle
									: periodNonSelectedStyle
							}
						>
							Monthly
						</Typography>
					</Box>
					<Box
						id="pointerCursor"
						sx={{
							borderRadius: "4px",
							padding: "6px 20px",
							background:
								period == "Annually"
									? mode == "light"
										? "#CCE6FF"
										: "rgba(204, 230, 255, 0.10)"
									: "transparent",
						}}
						onClick={() => {
							setPlanPeriod("Annually");
						}}
					>
						<Typography
							style={
								period == "Annually"
									? periodSelectedStyle
									: periodNonSelectedStyle
							}
						>
							Annually
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					gap: "10px",
				}}
			>
				<Box
					sx={{
						display: belowTablet ? "none" : "block",
						padding: "20px",
						border: mode == "light" ? "1px solid #F0F1F3" : "1px solid #1E202D",
						borderRadius: "4px",
						width: "35%",
					}}
				>
					<Typography
						fontSize={"12px"}
						fontWeight={"600"}
						color={mode == "light" ? "#18113C" : "#FFF"}
						mb={"21px"}
					>
						{selected?.displayHeading}
					</Typography>
					{selected?.keyFeatures?.map((item, index) => {
						return (
							<Typography
								key={index}
								fontSize={"14px"}
								fontWeight={"400"}
								color={"#696E7E"}
								mb={2}
							>
								<img
									src="/arrow/tick.svg"
									style={{
										marginRight: "10px",
									}}
								/>
								{item}
							</Typography>
						);
					})}
				</Box>
				<Box width={belowTablet ? "100%" : "70%"}>
					{skus?.map((item, index) => {
						return (
							<Box
								key={index}
								mb={skus?.length - 1 == index ? 0 : 2}
								id="pointerCursor"
								sx={{
									borderRadius: "4px",
									border:
										selected?.skuId == item?.skuId
											? "1px solid #0081FF"
											: mode == "light"
											? "1px solid #F0F1F3"
											: "1px solid #1E202D",
								}}
							>
								{currentPlan?.skuId == item?.skuId && (
									<Box display={"flex"} justifyContent={"end"}>
										<img
											src={
												mode == "light"
													? "/img/current-plan.svg"
													: "/img/current-plan-dark.svg"
											}
										/>
										<Typography
											fontSize={"10px"}
											fontWeight={"700"}
											color={"#0081FF"}
											sx={{
												position: "absolute",
												right: "35px",
												paddingTop: "2px",
											}}
										>
											Current Plan
										</Typography>
									</Box>
								)}
								<Box
									sx={{
										display: "flex",
										flexDirection: belowSmall ? "column" : "row",
										alignItems: belowSmall ? "start" : "",

										padding:
											currentPlan.skuId == item?.skuId
												? "0 17px 17px 17px"
												: "17px",
										justifyContent: "space-between",
									}}
									onClick={() => {
										setPlanType(item);
									}}
								>
									<Box
										width={belowSmall ? "100%" : "68%"}
										mb={belowSmall ? 1 : 0}
									>
										<Typography
											fontSize={"20px"}
											fontWeight={"700"}
											color={mode == "light" ? "#18113C" : "#FFF"}
										>
											{item?.displayName}
										</Typography>
										<Typography
											fontSize={"14px"}
											fontWeight={"400"}
											color={
												mode == "light"
													? "#696E7E"
													: "rgba(255, 255, 255, 0.50)"
											}
											sx={{
												opacity: "0.7",
											}}
										>
											{item?.internalDescription}
										</Typography>
									</Box>
									<Box
										display={!period == "Monthly" ? "block" : "flex"}
										flexDirection={"column"}
										justifyContent={!period == "Monthly" ? "end" : "center"}
									>
										<Box
											display={"flex"}
											justifyContent={"center"}
											alignItems={"baseline"}
										>
											<Typography
												fontSize={"30px"}
												fontWeight={"700"}
												color={mode == "light" ? "#18113C" : "#FFF"}
											>
												{period == "Monthly"
													? "$" + item?.recurringFee
													: "$" + (item?.annualRecurring / 12).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
											</Typography>
											<Typography
												ml={1}
												fontSize={"14px"}
												color={
													mode == "light"
														? "#696E7E"
														: "rgba(255, 255, 255, 0.50)"
												}
												fontWeight={"400"}
												sx={{
													opacity: "0.7",
												}}
											>
												/ month
											</Typography>
										</Box>
										<Box>
											<Typography
												ml={1}
												fontSize={"12px"}
												color={
													mode == "light"
														? "#696E7E"
														: "rgba(255, 255, 255, 0.50)"
												}
												fontWeight={"400"}
												sx={{
													opacity: "0.7",
												}}
											>
												{period == "Annually" ? "$"+item?.annualRecurring?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " billed annually" : <>&nbsp;</>}
											</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
						);
					})}
				</Box>
			</Box>
			<Box mt={2} display={"flex"} justifyContent={"end"}>
				<Box>
					<NextButton mode={mode} onClick={()=>{ChangeTab(2)} } id={isDisable(selected,currentPlan, period,currentCycle)?'disableCursor':' '}
                    />
				</Box>
			</Box>
		</Box>
	);
}

const isDisable=(selected,currentPlan, period,currentCycle)=>{
	console.log(period);
	console.log(currentPlan);
	console.log(currentCycle);
    if(selected?.skuId === currentPlan?.skuId && currentCycle?.toLowerCase() === period?.toLowerCase()){
        return true
    }
    return false
}