import {
	Box,
	Button,
	Divider,
	MenuItem,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { Input, Switch } from "antd";
import { useTheme } from "@mui/styles";
import styled from "styled-components";
import { CustomSelect} from "components/Inputs";
import React, { useEffect, useState }  from "react";
import { DownArrow } from "resources/Icons/Arrows";
import SettingsTable from "components/Table/SettingsTable";
import TableHeading from "components/Typography/TableHeading";
import MessagingModal from "components/Modal/Messaging";
import InternationalNumbers from "components/Modal/Settings/InternationalNumbers";
import { txtCartFetch } from "utilifyFunctions";
import { ReportProblemTwoTone } from "@mui/icons-material";

export default function Messaging({ settings, setSettings, getSettings }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const belowSmall = useMediaQuery(theme.breakpoints.down("md"));
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [open, setOpen] = useState(false);
	const [smartSendingDuration, setSmartSendingDuration] = useState(null);
	const [abandonedCartsEnabled, setAbandonedCartsEnabled] = useState(true);
	const [firstFollowUp, setFirstFollowUp] = useState(true);
	const [secondFollowUp, setSecondFollowUp] = useState(true);
	const [phones, setPhones] = useState([]);
	const [ukOpen, setUkOpen] = useState(false);
	const [auOpen, setAuOpen] = useState(false);

	const handlePhoneModal = function(country){
		if(country.includes("United States") || country.includes("Canada")){
			setOpen(true);
		} else if(country.includes("United Kingdom")){
			setUkOpen(true);
		} else if(country.includes("Australia")){
			setAuOpen(true);
		}
	}

	const handleUKSubmit = async function(){
		let body = {
			country: "GB"
		}
		const response = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/country/submit", body);
		if(response){
			alert("Request Submitted!");
			getSettings();
			setUkOpen(false);
		} else{
			alert("There was an issue processing your request. Please try again or contact support.");
		}
	}

	const handleAUSubmit = async function(){
		let body = {
			country : "AU"
		}
		const response = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/country/submit", body);
		if(response){
			alert("Request Submitted!");
			getSettings();
			setAuOpen(false);
		} else{
			alert("There was an issue processing your request. Please try again or contact support.");
		}
	}

	useEffect(() => {
		setSmartSendingDuration(parseInt(settings.smartFollowUpDuration));
		setAbandonedCartsEnabled(settings.abandonedCartsEnabled);
		setFirstFollowUp(settings.firstFollowUp);
		setSecondFollowUp(settings.secondFollowUp);
		setPhones(settings.phones);
	}, [settings]);

	function customSetSmartSendingDuration(value){
		setSettings('smartFollowUpDuration', value)
		setSmartSendingDuration(value); 
	}

	function customSAbandonedCartsEnabled(value){
		setSettings('abandonedCartsEnabled', value)
		setAbandonedCartsEnabled(value); 
	}

	function customSetFirstFollowUp(value){
		setSettings('firstFollowUp', value)
		setFirstFollowUp(value); 
	}

	function customSetSecondFollowUp(value){
		setSettings('secondFollowUp', value)
		setSecondFollowUp(value); 
	}

	const columns = [
		{
			dataIndex: "phoneNumber",
			key: "phoneNumber",
			title: <TableHeading minWidth={"150px"}>Phone Number</TableHeading>,
			sortable: false,
			width: "25%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
					lineHeight={"normal"}
					mb={"10px"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "type",
			key: "type",
			title: <TableHeading minWidth={"150px"}>Type</TableHeading>,
			sortable: false,
			width: "25%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
					lineHeight={"normal"}
					mb={"10px"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "country",
			key: "country",
			title: <TableHeading minWidth={"150px"}>Countries</TableHeading>,
			sortable: false,
			width: "25%",
			render: (params) => (
				<Typography
					fontSize={14}
					color={lightMode ? "#000D31" : "#FFF"}
					lineHeight={"normal"}
					mb={"10px"}
					fontFamily={"'NotoColorEmojiLimited', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "status",
			key: "status",
			title: <TableHeading minWidth={"200px"}>Status</TableHeading>,
			sortable: false,
			width: "22%",
			render: (params) =>
				params === "Verified" ? (
					<span
						style={{
							padding: "4px 14px",
							borderRadius: "4px",
							color: "#00BE5B",
							fontWeight: "500",
							fontSize: "12px",
							background: lightMode ? "#DBFBEE" : "rgba(0, 190, 91, 0.20)",
						}}
					>
						{params}
					</span>
				) : (params === "Rejected" || params === "Disabled") ? (
					<span
						style={{
							padding: "4px 14px",
							borderRadius: "4px",
							color: "#FF004C",
							fontWeight: "500",
							fontSize: "12px",
							background: " rgba(255, 0, 76, 0.10)",
						}}
					>
						{params}
					</span>
				) : params === "Pending" ? (
					<span
					style={{
						padding: "4px 14px",
						borderRadius: "4px",
						color: "#596176",
						fontWeight: "500",
						fontSize: "12px",
						background: "#edeef2",
					}}
				>
					{params}
				</span>
				) : (
					<span
					style={{
						padding: "4px 14px",
						borderRadius: "4px",
						color: "rgb(33, 150, 243)",
						fontWeight: "500",
						fontSize: "12px",
						background: "#EEF6FF",
					}}
				>
					{params}
				</span>
				),
		},
		{
			key: "country",
			dataIndex: "country",
			title: (
				<TableHeading minWidth={"90px"} maxWidth="90px">
					Action
				</TableHeading>
			),
			width: "50px",
			render: (country, row) => (
				<Button
					sx={{
						padding: "7px 15px",
						border: "1px solid #0081FF",
						backgroundColor: "transparent",
						width:"120px",
						marginRight:"10px"
					}}
					variant="outlined"
					onClick={() => handlePhoneModal(country)}
					disabled={row.type === "10DLC" || (row.type==="Toll-Free" && settings.planType==="Basic")|| (row.type === "Mobile" && row.status !== "Disabled")}
				>
					<Typography
						fontSize={"14px"}
						fontWeight={"600"}
						color={row.type === "10DLC" || (row.type==="Toll-Free" && settings.planType==="Basic")|| (row.type === "Mobile" && row.status !== "Disabled") ? "#darkgray" : "#0081FF"}
						lineHeight={"normal"}
					>
						{row.type === "10DLC" ? ("Enabled") 
						: row.type === "Toll-Free" && settings.planType === "Basic" ? ("Locked")
						: country.includes("United States") || country.includes("Canada") ? ( "Buy 10-DLC" ) 
						: row.status === "Disabled" ? ("Enable") : ("Enabled")}
					</Typography>
				</Button>
			),
		},
	];

	const TextInputNew = styled(Input)(() => ({
		height: "40px",
		fontSize: "14px",
		fontWeight: "500",
		background: "transparent",
		color: lightMode ? "#000D31" : "#FFF",
		borderRadius: "3px 0px 0px 3px !important",
		border: `1px solid ${lightMode ? "#E8EBF3" : "#1E202D"}`,
		"::placeholder": {
			color: "#808698",
			fontSize: "14px",
			fontWeight: "500",
		},
		"& .ant-input": {},
		
		
	}));
	return (
		<>
			<Box
				sx={{
					borderRadius: "12px",
					border: `1px solid ${theme?.palette?.card?.border}`,
					backgroundColor: theme?.palette?.card?.background,
				}}
			>
				<Typography
					p={"20px 0px 0px 20px"}
					fontSize={"16px"}
					fontWeight={600}
					color={theme?.palette?.pageHeading}
				>
					Your Numbers
				</Typography>
				<SettingsTable columns={columns} rows={phones} />
			</Box>
			<Box
				mt={"24px"}
				// p={"20px"}
				sx={{
					borderRadius: "12px",
					border: `1px solid ${theme?.palette?.card?.border}`,
					backgroundColor: theme?.palette?.card?.background,
				}}
			>
				<Typography
					fontSize={"16px"}
					pl={"20px"}
					pt={"20px"}
					fontWeight={600}
					color={theme?.palette?.pageHeading}
				>
					Quiet Hours
				</Typography>
				<InputWrapper>
					<Box width={"100%"}>
						<CustomLabel>
							Quiet Hours prevent your subscribers from receiving automated
							messages during specified hours (like the middle of the night). If
							an automated message is triggered during these hours, its delivery
							will be delayed until quiet hours are over.
						</CustomLabel>
					</Box>
				</InputWrapper>
				<Divider />

				<Typography
					fontSize={"16px"}
					pl={"20px"}
					pt={"20px"}
					fontWeight={600}
					color={theme?.palette?.pageHeading}
				>
					Quiet Hours Period
				</Typography>
				<InputWrapper>
					<Box width={"100%"}>
						<CustomLabel>
							The default Quiet Hours are 9pm to 8am. This means that if an
							automated message is triggered to send within these hours, it will
							be delayed until 8am the next day. Quiet Hours are based on the
							subscriber&apos;s time zone. If there’s no known time zone, the
							company time zone will be used.
						</CustomLabel>
					</Box>
					<Box display={"flex"} gap={"12px"} mt={"16px"} mb={"16px"}>
						<Box width={belowSmall ? "100%" : "33%"}>
							{" "}
							<CustomLabel>From</CustomLabel>
							<CustomSelect
								placeholder="09:00 PM"
								suffixIcon={<DownArrow />}
								disabled
							>
							</CustomSelect>
						</Box>
						<Box width={belowSmall ? "100%" : "33%"}>
							{" "}
							<CustomLabel>To</CustomLabel>
							<CustomSelect
								placeholder="08:00 AM"
								suffixIcon={<DownArrow />}
								disabled
							>
							</CustomSelect>
						</Box>
					</Box>
					<CustomLabel>
						Note: changing quiet hours has been restricted for optimal customer
						experience.
					</CustomLabel>
				</InputWrapper>
			</Box>
			<Box
				mt={"24px"}
				sx={{
					borderRadius: "12px",
					border: `1px solid ${theme?.palette?.card?.border}`,
					backgroundColor: theme?.palette?.card?.background,
				}}
			>
				<InputWrapper pt="20px">
					<FlexBox>
						<Box>
							<Typography
								fontSize={"16px"}
								// pl={"20px"}
								// pt={"20px"}
								mb={"8px"}
								lineHeight={"normal"}
								fontWeight={600}
								color={theme?.palette?.pageHeading}
							>
								Texting Status
							</Typography>
							<CustomLabel mb="0px">
							If disabled, we will no longer text and recover abandoned carts. This does not pause any subscription billing.
							</CustomLabel>
						</Box>
						<Box
							display={"flex"}
							gap="16px"
							alignItems={"center"}
							mt={isMobile && "12px"}
						>
							<Typography
								fontSize={"14px"}
								fontWeight={500}
								lineHeight={"normal"}
								color={mode == "light" ? "#0A0A18" : "#FFF"}
							>
								Disabled
							</Typography>
							<Switch checked={abandonedCartsEnabled} onChange={customSAbandonedCartsEnabled}/>
							<Typography
								fontSize={"14px"}
								fontWeight={500}
								lineHeight={"normal"}
								color={mode == "light" ? "#0081FF" : "#0081FF"}
							>
								Enabled
							</Typography>
						</Box>
					</FlexBox>
				</InputWrapper>
				<Divider />
				<InputWrapper pt="20px">
					<FlexBox>
						<Box>
							<Typography
								fontSize={"16px"}
								// pl={"20px"}
								// pt={"20px"}
								mb={"8px"}
								lineHeight={"normal"}
								fontWeight={600}
								color={theme?.palette?.pageHeading}
							>
								24 Hour Follow Up
							</Typography>
							<CustomLabel mb="0px">
								If enabled, a follow up will be sent to unrecovered abandoned
								carts 24 hours after the initial message.
							</CustomLabel>
						</Box>
						<Box
							display={"flex"}
							gap="16px"
							alignItems={"center"}
							mt={isMobile && "12px"}
						>
							<Typography
								fontSize={"14px"}
								fontWeight={500}
								lineHeight={"normal"}
								color={mode == "light" ? "#0A0A18" : "#FFF"}
							>
								Disabled
							</Typography>
							<Switch checked={firstFollowUp} onChange={customSetFirstFollowUp}/>
							<Typography
								fontSize={"14px"}
								fontWeight={500}
								lineHeight={"normal"}
								color={mode == "light" ? "#0081FF" : "#0081FF"}
							>
								Enabled
							</Typography>
						</Box>
					</FlexBox>
				</InputWrapper>
				<Divider />
				<InputWrapper pt="20px">
					<FlexBox>
						<Box>
							<Typography
								fontSize={"16px"}
								// pl={"20px"}
								// pt={"20px"}
								mb={"8px"}
								lineHeight={"normal"}
								fontWeight={600}
								color={theme?.palette?.pageHeading}
							>
								48 Hour Follow Up
							</Typography>
							<CustomLabel mb="0px">
								If enabled, a follow up will be sent to unrecovered abandoned
								carts 48 hours after the initial message.
							</CustomLabel>
						</Box>
						<Box
							display={"flex"}
							gap="16px"
							alignItems={"center"}
							mt={isMobile && "12px"}
						>
							<Typography
								fontSize={"14px"}
								fontWeight={500}
								lineHeight={"normal"}
								color={mode == "light" ? "#0A0A18" : "#FFF"}
							>
								Disabled
							</Typography>
							<Switch checked={secondFollowUp} onChange={customSetSecondFollowUp}/>
							<Typography
								fontSize={"14px"}
								fontWeight={500}
								lineHeight={"normal"}
								color={mode == "light" ? "#0081FF" : "#0081FF"}
							>
								Enabled
							</Typography>
						</Box>
					</FlexBox>
				</InputWrapper>
				<Divider />
				<InputWrapper pt="20px">
					<Typography
						fontSize={"16px"}
						// pl={"20px"}
						// pt={"20px"}
						mb={"12px"}
						lineHeight={"normal"}
						fontWeight={600}
						color={theme?.palette?.pageHeading}
					>
						Smart Sending
					</Typography>
					<CustomLabel mb="16px">
						Smart sending makes sure you do not message a customer more than
						once in the last X hours. It is applied automatically to automation
						messages. Optional for SMS campaigns.
					</CustomLabel>
					<Box
						display={"flex"}
						gap={"12px"}
						flexDirection={isMobile ? "column" : "row"}
					>
						<Box width={"100%"}>
							<CustomLabel>Smart Sending Period</CustomLabel>
							<Box display={"flex"}>
								<Box width={"95%"}>
									<TextInputNew 
											width={"100%"} 
											type="number" 
											value={smartSendingDuration}
											onChange={e => customSetSmartSendingDuration(e.target.value)}
									/>
								</Box>
								<Box
									width={"15%"}
									border={`1px solid ${theme?.palette?.card?.border}`}
									borderLeft={"none"}
									sx={{
										borderRadius: "0px 3px 3px 0px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Typography
										fontSize={14}
										fontWeight={500}
										lineHeight={"normal"}
										color={"#808698"}
									>
										Hour/s
									</Typography>
								</Box>
							</Box>
						</Box>
						<Box width={"100%"}></Box>
						<Box width={"100%"}></Box>
					</Box>
				</InputWrapper>
				<MessagingModal open={open} handleClose={() => setOpen(false)} settings={settings}/>
				<InternationalNumbers 
					open={ukOpen} 
					handleClose={() => setUkOpen(false)} 
					message="By selecting enable you will be able to send messages to the United Kingdom. Enabling will incur a $1.20/mo charge."
					title={"Enable United Kingdom 🇬🇧"}
					handleSubmit={handleUKSubmit}/>
				<InternationalNumbers 
					open={auOpen} 
					handleClose={() => setAuOpen(false)} 
					message="By selecting enable you will be able to send messages to the Australia. Enabling will incur a $6.50/mo charge."
					title={"Enable Australia 🇦🇺"}
					handleSubmit={handleAUSubmit}/>
			</Box>
		</>
	);
}

const InputWrapper = ({ children, ...props }) => {
	return (
		<Box p={"12px 20px 20px 20px"} {...props}>
			<Box
			// display={"Flex"}
			// gap={"12px"}
			// flexDirection={belowSmall ? "column" : "row"}
			>
				{children}
			</Box>
		</Box>
	);
};
const CustomLabel = ({ children, ...props }) => {
	return (
		<Typography
			fontSize={14}
			fontWeight={500}
			color={"#808698"}
			mb={"8px"}
			{...props}
		>
			{children}
		</Typography>
	);
};

const FlexBox = ({ children, ...props }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Box
			display={"flex"}
			justifyContent={"space-between"}
			flexDirection={isMobile ? "column" : "row"}
			alignItems={"center"}
			{...props}
		>
			{children}
		</Box>
	);
};



